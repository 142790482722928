/*
This list of address are from owner who held BOTH
Anchor Certificates: https://etherscan.io/address/0x600a4446094c341693c415e6743567b9bfc8a4a8
Paintings of Forgotten Souls: https://etherscan.io/address/0x6dae8a922b66225de1728bd9e5f2b7a4bdd4699b
*/

// This list is derived through using:
// https://dune.xyz/queries/499576 (overlap snapshot tool)
// Pasting array into sheet.
// Copying to https://arraythis.com/

// Last generated on 2022-04-11 10:00 EST
const overlap = ['0x202eca424a8db90924a4daa3e6bcecd9311f668e', '0xc3de4f9c763ec015deb165d0a96fbafa390286bc', '0x7911670881a81f8410d06053d7b3c237ce77b9b4', '0x68a7ac13477aad590982293feeeb786a00276cf2', '0x0cacc6104d8cd9d7b2850b4f35c65c1ecdeece03', '0x0491914687bab1f3606e2a77ed890ee3ac392ecf', '0x292d76ef68931cda7c2fb2b580df263dca3d9b9b', '0xae3a3d0a97fa75a340cc879700939699d775688c', '0xa4ad045d62a493f0ed883b413866448afb13087c', '0x13ebd3443fa5575f0eb173e323d8419f7452cfb1', '0xafe6520e39b77158e15b3377c1528b590a887800', '0x6cc044b1f4685a1a518406e1eb2abe1dd058241c', '0xd23ae11f017fe6db1aa74dd0138c2bff3b245bb6', '0x9dd78c90b154aca6d9c1b6b0bf4cfdf6088a19e5', '0xaf69610ea9ddc95883f97a6a3171d52165b69b03', '0xafbcc39f474baf9596c1135522810d5f409dde0f', '0xb88f61e6fbda83fbfffabe364112137480398018', '0xd3c1b9a1959b8be13252546871d37d83100caf8d', '0xdbf14da8949d157b57acb79f6eee62412b210900', '0xeefbada5539f9ae2c87c1151cdb2057398383342', '0xf7425fb026f9297fcc57b14ace187215442586a2', '0xf74fc3572526a69639966dbd0375a13dfca7a312', '0x002936cd9bcb36cd2b5f168edb16dc983afcc1c3', '0xf930b0a0500d8f53b2e7efa4f7bcb5cc0c71067e', '0x102ba31c397766e266729ceb8bd8c361b6c9d484', '0x1c051112075feaee33bcdbe0984c2bb0db53cf47', '0x231a07c825f052b895de5fd1513ce40d18e14af5', '0x2b1d2d290268cb4c4862d4a658f1c2a663c0f79a', '0x2e44f49552c0c8e93f60318520760f619c03615f', '0x317bc38b66566566529c41462ba774f489b4a63f', '0x4799b18582cbb51cc207ed9716034da309edf47e', '0x557670f1adcc957e753062c6663323ef14aff5c2', '0x5ba02f4ff6af1d9d2af8774d10fd32eb57d4e2e6', '0x77350e1152efd5f2d807a6124015c629a907155e', '0x8ce9ec296542ec95c0259572f1c8965c30cd7df0', '0x9cbbcd3b4129b1c00f0cd851baf118ebb0c4f168'];
module.exports = overlap
  